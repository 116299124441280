<template>
  <div>
    <v-container>
      <v-card class="mx-auto col-md-12 col-sm-12 col-xs-12 pa-0">
        <v-card-title class="d-flex justify-space-around pa-0">
          <h1 class="font-weight-medium text-center">{{ params.title }}</h1>
        </v-card-title>
      </v-card>
    </v-container>

    <v-container>
      <v-card class="mx-auto col-md-12 col-sm-12 col-xs-12">
        <v-tabs v-model="tab" background-color="transparent" grow>
          <v-tab :style="{ 'text-transform': 'capitalize' }">
            <v-icon>mdi-home</v-icon>
          </v-tab>
          <v-tab :style="{ 'text-transform': 'capitalize' }">
            <v-icon>mdi-page-next</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card-text>
              <v-row>
                <v-col
                  class="d-flex align-center"
                  cols="12"
                  xs="12"
                  sm="2"
                  md="2"
                >
                  <span
                    class="text-subtitle-2"
                    :style="{ color: 'initial !important' }"
                    >Servicio:</span
                  >
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-autocomplete
                    outlined
                    dense
                    hide-details
                    clearable
                    type="text"
                    :items="data_servicios"
                    v-model="item.srv_id"
                    :error="error.srv_id"
                    @change="error.srv_id = false"
                  ></v-autocomplete>
                </v-col>

                <v-col
                  class="d-flex align-center"
                  cols="12"
                  xs="12"
                  sm="2"
                  md="2"
                >
                  <span
                    class="text-subtitle-2"
                    :style="{ color: 'initial !important' }"
                    >Código DANE:</span
                  >
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    readonly
                    type="text"
                    v-model="doc.frcl_cdgo_dane"
                    :error="error.frcl_cdgo_dane"
                    @change="error.frcl_cdgo_dane = false"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  class="d-flex align-center"
                  cols="12"
                  xs="12"
                  sm="2"
                  md="2"
                >
                  <span
                    class="text-subtitle-2"
                    :style="{ color: 'initial !important' }"
                    >Radicado recibido:</span
                  >
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    readonly
                    type="text"
                    v-model="doc.frcl_id_rad_rec"
                    :error="error.frcl_id_rad_rec"
                    @change="error.frcl_id_rad_rec = false"
                  ></v-text-field>
                </v-col>

                <v-col
                  class="d-flex align-center"
                  cols="12"
                  xs="12"
                  sm="2"
                  md="2"
                >
                  <span
                    class="text-subtitle-2"
                    :style="{ color: 'initial !important' }"
                    >Fecha radicación:</span
                  >
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-text-field
                    v-model="date_radicado.value"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  class="d-flex align-center"
                  cols="12"
                  xs="12"
                  sm="2"
                  md="2"
                >
                  <span
                    class="text-subtitle-2"
                    :style="{ color: 'initial !important' }"
                    >Tipo trámite:</span
                  >
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-autocomplete
                    outlined
                    dense
                    hide-details
                    clearable
                    type="text"
                    :items="data_tipo_tramites"
                    v-model="item.tpt_id"
                    :error="error.tpt_id"
                    @change="error.tpt_id = false"
                  ></v-autocomplete>
                </v-col>

                <v-col
                  class="d-flex align-center"
                  cols="12"
                  xs="12"
                  sm="2"
                  md="2"
                >
                  <span
                    class="text-subtitle-2"
                    :style="{ color: 'initial !important' }"
                    >Causal:</span
                  >
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-autocomplete
                    outlined
                    dense
                    hide-details
                    clearable
                    type="text"
                    :items="data_causales"
                    v-model="item.csl_id"
                    :error="error.csl_id"
                    @change="error.csl_id = false"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  class="d-flex align-center"
                  cols="12"
                  xs="12"
                  sm="2"
                  md="2"
                >
                  <span
                    class="text-subtitle-2"
                    :style="{ color: 'initial !important' }"
                    >Nro. Cuenta:</span
                  >
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    clearable
                    type="text"
                    v-model="item.frcl_id_cta"
                    :error="error.frcl_id_cta"
                    @change="error.frcl_id_cta = false"
                  ></v-text-field>
                </v-col>

                <v-col
                  class="d-flex align-center"
                  cols="12"
                  xs="12"
                  sm="2"
                  md="2"
                >
                  <span
                    class="text-subtitle-2"
                    :style="{ color: 'initial !important' }"
                    >Nro. Factura:</span
                  >
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    clearable
                    type="text"
                    v-model="item.frcl_id_fact"
                    :error="error.frcl_id_fact"
                    @change="error.frcl_id_fact = false"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  class="d-flex align-center"
                  cols="12"
                  xs="12"
                  sm="2"
                  md="2"
                >
                  <span
                    class="text-subtitle-2"
                    :style="{ color: 'initial !important' }"
                    >Tipo respuesta:</span
                  >
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-autocomplete
                    outlined
                    dense
                    hide-details
                    clearable
                    type="text"
                    :items="data_tipo_respuestas"
                    v-model="item.tpr_id"
                    :error="error.tpr_id"
                    @change="error.tpr_id = false"
                  ></v-autocomplete>
                </v-col>

                <v-col
                  class="d-flex align-center"
                  cols="12"
                  xs="12"
                  sm="2"
                  md="2"
                >
                  <span
                    class="text-subtitle-2"
                    :style="{ color: 'initial !important' }"
                    >Fecha respuesta:</span
                  >
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-text-field
                    v-model="date_respuesta.value"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
          <v-tab-item>
            <v-card-text>
              <v-row>
                <v-col
                  class="d-flex align-center"
                  cols="12"
                  xs="12"
                  sm="2"
                  md="2"
                >
                  <span
                    class="text-subtitle-2"
                    :style="{ color: 'initial !important' }"
                    >Radicado respuesta:</span
                  >
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    readonly
                    type="text"
                    v-model="doc.frcl_id_rad_rpt"
                    :error="error.frcl_id_rad_rpt"
                    @change="error.frcl_id_rad_rpt = false"
                  ></v-text-field>
                </v-col>

                <v-col
                  class="d-flex align-center"
                  cols="12"
                  xs="12"
                  sm="2"
                  md="2"
                >
                  <span
                    class="text-subtitle-2"
                    :style="{ color: 'initial !important' }"
                    >Fecha notificación o ejecución:</span
                  >
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-menu
                    v-model="date_notificacion.estado"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_notificacion.value"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_notificacion.value"
                      @input="date_notificacion.estado = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  class="d-flex align-center"
                  cols="12"
                  xs="12"
                  sm="2"
                  md="2"
                >
                  <span
                    class="text-subtitle-2"
                    :style="{ color: 'initial !important' }"
                    >Tipo notificación:</span
                  >
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-autocomplete
                    outlined
                    dense
                    hide-details
                    clearable
                    type="text"
                    :items="data_tipo_notificaciones"
                    v-model="item.tpn_id"
                    :error="error.tpn_id"
                    @change="error.tpn_id = false"
                  ></v-autocomplete>
                </v-col>

                <v-col
                  class="d-flex align-center"
                  cols="12"
                  xs="12"
                  sm="2"
                  md="2"
                >
                  <span
                    class="text-subtitle-2"
                    :style="{ color: 'initial !important' }"
                    >Fecha traslado a SSPD:</span
                  >
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-menu
                    v-model="date_traslado.estado"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_traslado.value"
                        :error="error.frcl_fc_tld_sspd"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_traslado.value"
                      @input="date_traslado.estado = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row>
                <v-col
                  class="d-flex align-center"
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                >
                  <span
                    class="text-subtitle-2"
                    :style="{ color: 'initial !important' }"
                    >Observaciones:</span
                  >
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12">
                  <v-textarea
                    filled
                    auto-grow
                    rows="5"
                    row-height="25"
                    hide-details
                    v-model="item.frcl_obsv"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>

        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="d-flex justify-space-around text-center">
            <v-col class="pb-2">
              <v-btn
                class="mx-3 white--text blue-bootstrap"
                @click="checForm(true)"
              >
                {{ params.buttontext }}
              </v-btn>
            </v-col>
            <v-col class="pb-2">
              <v-btn
                class="mx-3 white--text green-bootstrap"
                @click="checForm(false)"
              >
                Guardar
              </v-btn>
            </v-col>
            <v-col class="pb-2">
              <v-btn class="mx-3 red-bootstrap white--text" @click="cancel">
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
    <ModalForm
      :visible="loader.estado"
      @close="loader.estado = false"
      :message="loader.message"
    />
  </div>
</template>

<script>
import ModalForm from "../modal/ModalForm.vue";

export default {
  components: {
    ModalForm,
  },

  data: () => ({
    tab: null,
    close: false,

    params: {
      add: true,
      title: "",
      buttontext: ""
    },
    loader: {
      estado: false,
      message: "",
    },

    data_servicios: [],
    data_tipo_tramites: [],
    data_tipo_respuestas: [],
    data_causales: [],
    data_tipo_notificaciones: [],

    item: {
      srv_id: null,
      //frcl_cdgo_dane: null,
      //frcl_id_rad_rec: null,
      //frcl_fc_rad: null,
      tpt_id: null,
      csl_id: null,
      frcl_id_cta: null,
      frcl_id_fact: null,
      tpr_id: null,
      //frcl_id_rad_rpt: null,
      //frcl_fc_rpt: null,
      frcl_fc_ntf: null,
      tpn_id: null,
      frcl_fc_tld_sspd: null,
      frcl_obsv: null,
    },

    error: {
      srv_id: false,
      frcl_cdgo_dane: false,
      frcl_id_rad_rec: false,
      frcl_fc_rad: false,
      tpt_id: false,
      csl_id: false,
      frcl_id_cta: false,
      frcl_id_fact: false,
      tpr_id: false,
      frcl_fc_rpt: false,
      frcl_id_rad_rpt: false,
      frcl_fc_ntf: false,
      tpn_id: false,
      frcl_fc_tld_sspd: false,
    },
    date_radicado: {
      estado: false,
      value: null,
    },
    date_respuesta: {
      estado: false,
      value: null,
    },
    date_notificacion: {
      estado: false,
      value: null,
    },
    date_traslado: {
      estado: false,
      value: null,
    },
    doc: {
      frcl_cdgo_dane: null,
      frcl_id_rad_rec: null,
      frcl_fc_rad: null,
      frcl_fc_rpt: null,
      frcl_id_rad_rpt: null
    }    
  }),
  created() {
    let { id, add, dcm_id } = this.$route.query;
    this.params.add = String(add) == "true" ? true : false;

    if (dcm_id){
      this.getDocData(dcm_id, (err) => {
        if (err){
          this.$emit("msj", { text: "Error al consultar el documento relacionado." });
        } else {
          this.getIdByDoc(dcm_id, (err, _id) => {
            if (err){
              this.$emit("msj", { text: "Error al consultar la reclamación" });
            } else if (_id){
              this.params.add = false;
              this.getData(_id);
            } else {
              this.params.add = true;
              this.getData(_id);
            }
          });
        }
      });
    } else {
      this.getData(id);
    }
  },

  watch: {
    "item.srv_id": function (val) {
      this.getCausales(val);
    },
  },

  methods: {
    getDocData(id, callback) {
      let uri = "/docs/reclamacion/" + id;

      this.axios
        .get(uri)
        .then((response) => {
          let frcl_fc_rad = response.data.frcl_fc_rad || null;
          let frcl_fc_rpt = response.data.frcl_fc_rpt || null;

          this.date_radicado.value = this.formatDate(frcl_fc_rad);
          this.date_respuesta.value = this.formatDate(frcl_fc_rpt);

          this.doc = response.data;

          return callback(false);
        })
        .catch((error) => {
          console.log(error);
          this.$emit("msj", { text: error.message });
          return callback(true);
        });
    },
    getData(id) {
      if (this.params.add) {
        this.params.title = "Reclamación";
        this.params.buttontext = "Agregar Reclamación";
      } else {
        this.params.title = "Actualizar Reclamación";
        this.params.buttontext = "Actualizar Reclamación";
        this.getItem(id);
      }

      this.loader.estado = true;
      this.loader.message = "Por favor espere mientras se consultan los datos..";

      Promise.all([
        this.getServicios(),
        this.getTipoTramite(),
        this.getTipoRespuesta(),
        this.getTipoNotificacion(),
      ]).then(() => {
        setTimeout(() => (this.loader.estado = false), 500);
      });
    },
    getItem(id) {
      let uri = "/formatoreclamos/edit/" + id;

      this.axios
        .get(uri)
        .then((response) => {
          //let frcl_fc_rad = response.data.frcl_fc_rad || null;
          //let frcl_fc_rpt = response.data.frcl_fc_rpt || null;
          let frcl_fc_ntf = response.data.frcl_fc_ntf || null;
          let frcl_fc_tld_sspd = response.data.frcl_fc_tld_sspd || null;

          //this.date_radicado.value = this.formatDate(frcl_fc_rad);
          //this.date_respuesta.value = this.formatDate(frcl_fc_rpt);
          this.date_notificacion.value = this.formatDate(frcl_fc_ntf);
          this.date_traslado.value = this.formatDate(frcl_fc_tld_sspd);

          this.item = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$emit("msj", { text: error.message });
        });
    },
    getIdByDoc(id, callback) {
      let uri = "/formatoreclamos/findbydoc/" + id;

      this.axios
        .get(uri)
        .then((response) => {
          if (response.data){
            return callback(false, response.data._id);
          } else {
            return callback(false, null);
          }
        })
        .catch((error) => {
          console.log(error);
          return callback(true, null);
        });
    },    
    getServicios() {
      let uri = "/servicios/" + String(this.$store.state.company);

      this.axios
        .get(uri)
        .then(async (response) => {
          let params = [response.data, "srv_cdgo", "srv_nombre"];
          this.data_servicios = await this.orderData(params);
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },
    getTipoTramite() {
      let uri = "/tipotramites/" + String(this.$store.state.company);

      this.axios
        .get(uri)
        .then(async (response) => {
          let params = [response.data, "tpt_cdgo", "tpt_nombre"];
          this.data_tipo_tramites = await this.orderData(params);
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },
    getTipoRespuesta() {
      let uri = "/tiporespuestas/" + String(this.$store.state.company);

      this.axios
        .get(uri)
        .then(async (response) => {
          let params = [response.data, "tpr_cdgo", "tpr_nombre"];
          this.data_tipo_respuestas = await this.orderData(params);
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    getCausales(val) {
      let uri = "/causales/get_by_service/" + String(this.$store.state.company);
      this.loader.estado = true;

      this.axios
        .post(uri, { srv_id: [val] })
        .then(async (response) => {
          let params = [response.data, "csl_cdgo", "csl_det"];
          this.data_causales = await this.orderData(params);
          this.loader.estado = false;
        })
        .catch((error) => {
          this.loader.estado = false;
          this.$emit("msj", { text: error.message });
        });
    },

    getTipoNotificacion() {
      let uri = "/tiponotificaciones/" + String(this.$store.state.company);

      this.axios
        .get(uri)
        .then(async (response) => {
          let params = [response.data, "tpn_cdgo", "tpn_nombre"];
          this.data_tipo_notificaciones = await this.orderData(params);
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    async checForm(close) {
      let data = this.item;

      if (data.tpt_id) {      
        var tpt_id = data.tpt_id;
        var tpt = this.data_tipo_tramites.find(d => d.value === tpt_id) || [];
      }

      if (!data.srv_id) {
        this.error.srv_id = true;
        this.$emit("msj", { text: "Servicio es obligatorio" });
      /*
      } else if (!data.frcl_cdgo_dane) {
        this.error.frcl_cdgo_dane = true;
        this.$emit("msj", { text: "Código DANE es obligatorio" });
      } else if (!data.frcl_id_rad_rec) {
        this.error.frcl_id_rad_rec = true;
        this.$emit("msj", { text: "Número radicado es obligatorio" });
      */
      } else if (!data.tpt_id) {
        this.error.tpt_id = true;
        this.$emit("msj", { text: "Tipo de tramite es obligatorio" });
      } else if (!data.csl_id) {
        this.error.csl_id = true;
        this.$emit("msj", { text: "Causal es obligatorio" });
      } else if (!data.frcl_id_cta) {
        this.error.frcl_id_cta = true;
        this.$emit("msj", { text: "Número de cuenta es obligatorio" });
      } else if (!data.frcl_id_fact) {
        this.error.frcl_id_fact = true;
        this.$emit("msj", { text: "Número factura es obligatorio" });
      } else if (!data.tpr_id) {
        this.error.tpr_id = true;
        this.$emit("msj", { text: "Tipo de respuesta es obligatorio" });
      /*
      } else if (!data.frcl_id_rad_rpt) {
        this.error.frcl_id_rad_rpt = true;
        this.$emit("msj", { text: "Número de respuesta es obligatorio" });
      */
      } else if (!data.tpn_id) {
        this.error.tpn_id = true;
        this.$emit("msj", { text: "Tipo de notificacion es obligatorio" });
      } else if (tpt.code === 5 && !this.date_traslado.value){
        this.error.frcl_fc_tld_sspd = true;
        this.$emit("msj", { text: "Si el código del tipo de tramite es 5, la fecha de traslado a la Sspd es obligatoria." });
      } else {
        this.close = close;

        this.getDates();

        if (this.params.add) this.createFormatoReclamo();
        else this.updateFormatoReclamo();
      }
    },

    createFormatoReclamo() {
      this.item.cmp_id = String(this.$store.state.company);
      this.item.dcm_id = String(this.$route.query.dcm_id);
      this.axios
        .post("/formatoreclamos/add", this.item)
        .then(() => {
          if (this.close) this.cancel();
          this.$emit("msj", { text: "Registro guardado", estado: "success" });
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    updateFormatoReclamo() {
      let uri = "/formatoreclamos/update/" + this.item._id;

      this.axios
        .put(uri, this.item)
        .then(() => {
          if (this.close) this.cancel();
          this.$emit("msj", {
            text: "Registro actualizado",
            estado: "success",
          });
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    getDates() {
      debugger;
      if (this.date_notificacion.value){
        this.item.frcl_fc_ntf = new Date(this.date_notificacion.value).toISOString();
      } else {
        this.item.frcl_fc_ntf = null;
      }
      if (this.date_traslado.value){
        this.item.frcl_fc_tld_sspd = new Date(this.date_traslado.value).toISOString();
      } else {
        this.item.frcl_fc_tld_sspd = null;
      }
    },

    formatDate(date) {
      if (!date) return null;
      else {
        let fecha = date.substring(0, 10);

        if (fecha == "1970-01-01") return null;
        else return fecha;
      }
    },

    cancel() {
      if (!this.$route.query.reclamos){
        this.$router.replace({ name: 'CreateDoc', params: { 'id': this.$route.query.dcm_id, 'add': false, 'search': this.$route.query.search } });
      } else {
        this.$router.push({ name: "DisplayFormatoReclamo" });
      }
    },

    async orderData(data = []) {
      let [array, cod, name] = data;
      let new_data = [];

      for await (const item of array) {
        new_data.push({
          text: `${item[cod]} - ${item[name]}`,
          value: item._id,
          code: item[cod]
        });
      }

      return new_data;
    },
  },
};
</script>